import React from 'react'
import aa from 'search-insights'
import Autocomplete from "../Autocomplete"
import { Configure } from 'react-instantsearch-dom'
import algolia from '../../../config/algolia.json'

export const Algolia = ({ mobile }) => {
  aa('init', {
    appId: algolia.appId,
    apiKey: algolia.publicSearchOnlyKey,
    useCookie: true
  })

  return (
    <div
      id="searchbar"
      className={`p-0 col form-group text-center ${!mobile ? 'desktop mt-3' : ''}`}
      style={{ maxWidth: "500px" }}
    >
      <Configure clickAnalytics />
      <Autocomplete mobile={mobile} />
    </div>
  )
}

export default Algolia