import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'

const ChildList = ({ header, headerUrl, list, activePage, expanded, setExpanded }) => {
  const maxHeight = expanded ? ((list.length * 70) + 10) : 0

  return (
    <div data-testid="child-list" className="text-left p-2">
      <button
        onClick={() => setExpanded(expanded ? "Collapsed" : header)}
        className={`children-group-header p-0 ${expanded ? 'expanded' : 'collapsed'}`}>
        <div className="text-left">
          <div>{header}</div>
        </div>
        <div className="text-right">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </button>
      <ul className="children-group-list p-0 ml-2" style={{ maxHeight, overflow: 'hidden' }}>
        {
          list.map((child, i) => {
            let active = activePage.url === child.url
            return (
              <li key={i} className={`${active ? 'active pb-2' : 'pb-2'} ${i === 0 ? 'pt-2' : ''}`} >
                <Link activeClassName="active" to={`${headerUrl}/${child.url}/`}>{child.name}</Link>
              </li>
            )
          })
        }
        {
          <li className='pb-2 see-all no-indent no-bold'>
            <Link activeClassName="active" to={`${headerUrl}/`}>See All {header}</Link>
          </li>
        }
      </ul>
    </div>
  )
}

export const ChildrenProductColumn = ({ parent, activePage, dropdownIsShowing }) => {
  const [expanded, setExpanded] = useState(false)
  let baseUrl = `/products/${parent?.url}`
  let childlessChildren = []

  useEffect(() => {
    setExpanded(false)
  }, [dropdownIsShowing])

  if (parent) {
    return (
      <div className="py-3">
        <div data-testid="children-list" className="children-list">
          {
            parent.children?.map((child, ind) => {
              if (child.children?.length > 0) {
                if (!expanded && (child.url === activePage.url || child.children.some(grandchild => grandchild.url === activePage.url))) setExpanded(child.name)
                return <ChildList
                  key={ind}
                  header={child.name}
                  headerUrl={`${baseUrl}/${child.url}`}
                  list={child.children}
                  activePage={activePage}
                  expanded={expanded === child.name}
                  setExpanded={setExpanded} />
              } else {
                if (!expanded && child.url === activePage.url) setExpanded(parent.name)
                childlessChildren.push(child)
                return null
              }
            })
          }
          {
            childlessChildren.length > 0 &&
            <ChildList
              header={parent.name}
              headerUrl={baseUrl}
              list={childlessChildren}
              activePage={activePage}
              expanded={expanded === parent.name}
              setExpanded={setExpanded} />
          }
        </div>
        <div className="see-all p-2">
          <div><Link to={`/products/${parent.url}/`} className="m-0">See All {parent.name}</Link></div>
        </div>
      </div>
    )
  } else return null
}

export default ChildrenProductColumn