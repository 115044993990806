import React from 'react'
import NeedHelp from './components/NeedHelp'
import Premiere from './components/Premiere'

export const DESKTOP_LARGE_MIN_WIDTH = 1200;
export const DESKTOP_MIN_WIDTH = 992;
export const TABLET_MIN_WIDTH = 768;
export const BASE_BREADCRUMBS = [
  { name: "Home", url: "/" }
]
export const BASE_BREADCRUMBS_PRODUCTS = [
  ...BASE_BREADCRUMBS,
  { name: "Products", url: "/products" }
]

export const DEFAULT_IMG_URL = "https://res.cloudinary.com/dthskrjhy/image/upload/c_fill,w_350,h_233/v1640632544/Typenex/new-website-images/NoImageAvailable.png"

export const CUSTOM_HEADER_COMPONENTS = {
  needHelp: <NeedHelp />,
  premiere: <Premiere />
}