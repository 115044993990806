import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'
import SliderMenu from '../SliderMenu'

export const ProductsDropdownMobile = ({ productNode, productsDropdownClicked, setProductsDropdownClicked, menuHeight }) => {

  return (
    <div className="products-dropdown-mobile">
      <button className="p-0 w-100 d-flex justify-content-between" onClick={() => setProductsDropdownClicked(productsDropdownClicked + 1)}>
        <div>
          Products
        </div>
        <div>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </button>
      <SliderMenu hierarchyRoot={productNode} rootClicked={productsDropdownClicked} minHeight={menuHeight} />
    </div>
  )
}

export default ProductsDropdownMobile