import React, { useState, useEffect } from 'react'
import SliderMenuItem from './SliderMenuItem'
import SliderMenuPane from './SliderMenuPane'

const paneGeneratorRecur = (children, currentPane, parentUrl, panes, paneStack, setPaneStack) => {
  let paneUrl = `${parentUrl}/${currentPane.url}`
  let pane = (
    <SliderMenuPane
      id={paneUrl}
      paneName={currentPane.name}
      customFooter={currentPane.name !== "Products" && <SliderMenuItem link goto={paneUrl} text={`See All ${currentPane.name}`} seeAll />}
      paneStack={paneStack}
      setPaneStack={setPaneStack}>
      {
        children.map((child, ind) => {
          return <SliderMenuItem
            key={ind}
            goto={`${paneUrl}/${child.url}`}
            text={child.name}
            link={!(child.children?.length > 0)}
            paneStack={paneStack}
            setPaneStack={setPaneStack} />
        })
      }
    </SliderMenuPane>
  )
  panes.push({ id: paneUrl, component: pane })
  children.forEach(child => {
    if (child.children?.length > 0) {
      paneGeneratorRecur(child.children, child, `${parentUrl}/${currentPane.url}`, panes, paneStack, setPaneStack)
    }
  })
  return panes
}

export const SliderMenu = ({ hierarchyRoot, rootClicked, minHeight }) => {
  const [paneStack, setPaneStack] = useState([])
  const [allPanes, setAllPanes] = useState([])
  useEffect(() => {
    const panes = paneGeneratorRecur(hierarchyRoot.children, hierarchyRoot, '', [], paneStack, setPaneStack)
    setAllPanes(panes)
  }, [hierarchyRoot, paneStack, setPaneStack])

  useEffect(() => {
    if (rootClicked > 0) {
      setPaneStack([`/${hierarchyRoot.url}`])
    } else {
      setPaneStack([])
    }
  }, [hierarchyRoot, rootClicked])

  return (
    <div className={`slider-menu`}>
      {
        allPanes.map(({ id, component }, ind) => {
          return (
            <div key={ind} style={{ minHeight }} className={`slider-menu-pane-container ${id === paneStack[paneStack.length - 1] ? 'showing' : 'hidden'} ${paneStack.includes(id) ? 'already-seen' : 'to-be-seen'}`}>
              {component}
            </div>
          )
        })
      }
    </div>
  )
}

export default SliderMenu