import React from 'react'
import { Link } from 'gatsby'
import './styles.scss'

export const Breadcrumbs = ({ data }) => {
  const buildUrl = (ind) => {
    let url = '';
    for (let i = 0; i <= ind; i++) {
      if (i > 1) url += '/'
      if (!(i === 0)) {
        url += data[i].url
      }
    }
    return url
  }

  return (
    <div className="container mt-0">
      <div data-testid="breadcrumbs" className="breadcrumbs d-flex flex-wrap">
        {
          data.map((breadcrumb, ind) => {
            return (
              <div key={ind} className="breadcrumb-child">
                <Link to={ind === 0 ? '/' : `${buildUrl(ind)}/`}>{breadcrumb.name}</Link>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Breadcrumbs