import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faSearch } from '@fortawesome/free-solid-svg-icons'
import DesktopNavbar from "./DesktopNavbar"
import HamburgerIcon from "../../icons/HamburgerIcon"
import "./styles.scss"
import Algolia from "../Algolia"

export const HeaderDesktop = ({ breadcrumbs }) => {
  return (
    <>
      <div className="navbar py-4 row">
        <div className="mobile-nav-icon col">{/*This div is only used for a few seconds when the production site first loads and the viewport is mobile width */}
          <HamburgerIcon />
        </div>
        <div className="col navbar-brand">
          <Link to="/">
            <img data-testid="logo"
              className="logo"
              src={`https://res.cloudinary.com/dthskrjhy/image/upload/c_scale,h_40/v1642518948/Typenex/new-website-images/typenexLogo.png`}
              alt="Typenex Medical Logo"
            />
          </Link>
        </div>
        <Algolia />
        <div className="col phone text-right">
          <FontAwesomeIcon icon={faPhone} width={"16px"} />
          <span data-testid="phoneNumber" className="ml-2">866-897-3639</span>
        </div>
        <div className="col mobile-search-icon text-center">{/*This div is only used for a few seconds when the production site first loads and the viewport is mobile width */}
          <FontAwesomeIcon icon={faSearch} width={"19px"} style={{ position: 'relative', bottom: '6px', left: '7px' }} />
        </div>
      </div>
      <DesktopNavbar breadcrumbs={breadcrumbs} />
    </>
  )
}

export default HeaderDesktop
