import React, { useState, useEffect } from 'react'
import ChildrenProductColumn from '../ChildrenProductColumn'
import ParentProductColumn from '../ParentProductColumn'
import { useWindowSize } from '../../hooks/useWindowResize'
import './styles.scss'
import { OutsideAlerter } from '../OutsideAlerter'
import { useHierarchy } from '../../context/hierarchy-context'

const hitsBrowserEdgeAt = 1138; // Width (in pixels) where the dropdown hits the edge of the browser

const getInitActiveParent = (parents, breadcrumbs) => {
  if (parents?.length > 0) {
    if (breadcrumbs?.length > 2) { // For any breadcrumb we care about, the first two would always be "home" and "products"
      return parents.find(node => node.url === breadcrumbs[2].url)
    } else return parents[0]
  }
}

export const ProductsDropdown = ({ breadcrumbs }) => {
  const { width } = useWindowSize()
  const { hierarchy } = useHierarchy()
  const initialActiveParent = getInitActiveParent(hierarchy?.children, breadcrumbs)
  const [isShowing, setIsShowing] = useState(false)
  const [activeParent, setActiveParent] = useState(initialActiveParent)
  let activePage = breadcrumbs?.length > 0 && breadcrumbs[breadcrumbs.length - 1]

  useEffect(() => {
    setActiveParent(initialActiveParent)
  }, [isShowing, initialActiveParent])
  return (
    <OutsideAlerter onOutsideClick={() => setIsShowing(false)} testid="products-dropdown">
      <button onClick={() => setIsShowing(!isShowing)} className="products-dropdown-btn">
        Products
      </button>
      <div className={`products-dropdown row ${isShowing ? 'show' : 'hide'} ${width <= hitsBrowserEdgeAt ? 'edge' : ''}`}>
        <div className="col-6 parent border-right">
          <ParentProductColumn parents={hierarchy?.children || []} active={activeParent} setActiveParent={setActiveParent} />
        </div>
        <div className="col-6 children">
          <ChildrenProductColumn parent={activeParent} activePage={activePage} dropdownIsShowing={isShowing} />
        </div>
      </div>
    </OutsideAlerter>
  )
}

export default ProductsDropdown