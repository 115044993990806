import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'

export const SliderMenuPane = ({ id, paneName, children, customFooter, paneStack, setPaneStack }) => {
  return (
    <div id={id} className={`slider-menu-pane`}>
      <button className="slider-back-btn w-100 px-5 pt-4 pb-3 text-center" onClick={() => {
        let updatedPanes = paneStack.slice()
        updatedPanes.pop()
        setPaneStack([...updatedPanes])
      }}>
        <FontAwesomeIcon icon={faChevronLeft} />
        {paneName}
      </button>
      {
        children
      }
      {
        customFooter || null
      }
    </div>
  )
}

export default SliderMenuPane