import React from 'react'

export const Premiere = () => {
  return (
    <div className="d-lg-flex d-block mt-4">
      <div className="mt-3 mt-lg-4">
        <img alt="Premier Contracted Supplier" src="https://res.cloudinary.com/dthskrjhy/image/upload/c_scale,w_168/v1615561463/Typenex/premier.png" />
      </div>
      <div className="ml-lg-4 mt-2 mt-lg-4">
        <span className="font-weight-semi-bold">Premier Members</span>, activate pricing
        <a className="ml-1 link" href="https://sso.premierinc.com/signin/v1/identifier?goto=https://premierconnect.premierinc.com:443/">
          here</a>. <br />
        Contract #PP‑LA‑580
      </div>
    </div>
  )
}

export default Premiere