import React from 'react'
import { withTransformation } from '../../utils'
import ImageCarousel from '../ImageCarousel'
import './styles.scss'

export const BannerCarousel = ({ data, name }) => {
  return (
    <ImageCarousel>
      {
        data.map((img, ind) => {
          return (
            <div key={ind} className="banner">
              <img alt={name} src={withTransformation(img.image, 'c_scale,w_1920')} />
              {
                img.link &&
                <div className="banner-caption">
                  <a href={img.link}>
                    <p className="banner-link-header my-2">{img.linkHeader}</p>
                    <p className="banner-link-subheader my-2">{`${img.linkSubheader} >>`}</p>
                  </a>
                </div>
              }
            </div>
          )
        })
      }
    </ImageCarousel>
  )
}

export default BannerCarousel