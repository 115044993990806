import React from "react"
import { connectAutoComplete } from "react-instantsearch-dom"
import { Link } from "gatsby"
import HitCard from "../HitCard"
import "./styles.scss"
import { OutsideAlerter } from "../OutsideAlerter"

const Autocomplete = ({ hits, currentRefinement, refine, mobile }) => {
  const searchResults = { hits, searchQuery: currentRefinement }

  return (
    <OutsideAlerter onOutsideClick={() => refine('')}>
      <input
        type="search"
        value={currentRefinement}
        className={`form-control ${mobile ? 'mobile-input' : ''}`}
        placeholder="Search products"
        onChange={event => refine(event.currentTarget.value)}
      />
      {currentRefinement ? (
        <div className={`searchDropdown ${mobile ? 'mobile' : ''}`}>
          {hits.length > 0 ? hits.slice(0, 5).map((hit, ind) => (
            <HitCard key={ind} hit={hit} />
          ))
            :
            <div className={`${mobile ? 'm-5' : 'py-2'} px-2`}>No Results Found</div>
          }
          {hits.length >= 5 &&
            <div className="sub text-right">
              <Link to="/searchresults/" state={searchResults}>See All Results</Link>
            </div>
          }
        </div>
      ) : null}
    </OutsideAlerter>
  )
}

const CustomAutocomplete = connectAutoComplete(Autocomplete)

export default CustomAutocomplete
