/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import algoliasearch from "algoliasearch/lite"
import algolia from "../../../config/algolia.json"
import { InstantSearch } from "react-instantsearch-dom"
import BannerCarousel from "../BannerCarousel"
import Header from "../Header"
import Footer from "../Footer"
import LiveChat from "../LiveChat"
import PageHeader from "../PageHeader"
import Breadcrumbs from "../Breadcrumbs"
import { useOverlay } from "../../context/overlay-context"
import { getAlgoliaIndex } from "../../utils"
import Helmet from "react-helmet"
import favicon from "../../images/typenex-favicon.png"
import "./styles.scss"

const searchClient = algoliasearch(algolia.appId, algolia.publicSearchOnlyKey)

const Layout = ({
  children,
  banners,
  pageTitle,
  hierarchy,
  customPageHeaderComponent,
  breadcrumbs
}) => {
  const { overlay } = useOverlay()

  return (
    <InstantSearch indexName={getAlgoliaIndex()} searchClient={searchClient}>
      <Helmet>
        <link rel="icon" href={favicon} type="image/png" />
      </Helmet>
      <LiveChat />
      <Header breadcrumbs={breadcrumbs} />
      <div className={`layout bottom-margin`}>
        <div className={`overlay ${overlay ? 'show' : 'hide'}`} />
        {banners && <BannerCarousel data={banners.data} name={banners.name} />}
        <main>
          {pageTitle && <PageHeader title={pageTitle} custom={customPageHeaderComponent} />}
          {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
          <div className="container">
            {children}
          </div>
        </main>
      </div>
      <Footer />
    </InstantSearch>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
