import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'


export const SliderMenuItem = ({ goto, text, link, paneStack, setPaneStack, seeAll }) => {
  if (!link) {
    return (
      <button className="px-5 py-2 w-100 d-flex justify-content-between" onClick={() => setPaneStack([...paneStack, goto])}>
        <div>
          {text}
        </div>
        <div>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </button>
    )
  } else {
    return (
      <div className={`px-5 py-2 ${seeAll ? 'see-all no-bold' : ''}`}>
        <Link to={`${goto}/`}>{text}</Link>
      </div>
    )
  }
}

export default SliderMenuItem