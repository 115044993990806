import React from 'react'
import { connectHitInsights } from 'react-instantsearch-dom'
import { Link } from 'gatsby'
import { DEFAULT_IMG_URL } from '../../constants'
import aa from 'search-insights'

export const HitCard = ({ hit, insights }) => {
  return (
    <Link
      to={`${hit.url}/`}
      className="card"
      onClick={() => {
        insights('clickedObjectIDsAfterSearch', {
          eventName: 'Search Result Clicked'
        })
      }}>
      <div className="img-container">
        <img
          alt={hit.name}
          src={hit.image || DEFAULT_IMG_URL}
        />
      </div>
      <div>
        <div className="ml-3 header">{hit.name}</div>
        {hit.parent !== "Products" &&
          <div className="ml-3 subheader">{`${hit.parent} > ${hit.name}`}</div>
        }
      </div>
    </Link>
  )
}

const Hit = connectHitInsights(aa)(HitCard)

export default Hit