import React from 'react'
import { Link } from 'gatsby'
import ProductsDropdown from '../ProductsDropdown'
import './styles.scss'

export const DesktopNavbar = ({ breadcrumbs }) => {
  return (
    <div id="navbar-links" className="p-3 d-flex justify-content-center text-center">
      <div className="mx-3">
        <Link data-testid="home-link" activeClassName="active" to="/">Home</Link>
      </div>
      <div className="mx-3 products-dd">
        <ProductsDropdown breadcrumbs={breadcrumbs} />
      </div>
      <div className="mx-3">
        <Link data-testid="international-link" activeClassName="active" to="/international/">International</Link>
      </div>
      <div className="mx-3">
        <Link data-testid="about-link" activeClassName="active" to="/about/">About</Link>
      </div>
      <div className="mx-3">
        <Link data-testid="contact-us-link" activeClassName="active" to="/contact/">Contact&nbsp;Us</Link>
      </div>
      <div className="mx-3">
        <Link data-testid="press-link" activeClassName="active" to="/press/">Press</Link>
      </div>
    </div>
  )
}

export default DesktopNavbar