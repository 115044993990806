import React from "react"
import { useWindowSize } from "../../hooks/useWindowResize"
import { TABLET_MIN_WIDTH } from "../../constants"
import HeaderMobile from "../HeaderMobile"
import HeaderDesktop from "../HeaderDesktop"

export const Header = ({ breadcrumbs }) => {
  const { width } = useWindowSize()
  return (
    <header>
      {
        width < TABLET_MIN_WIDTH ?
          <HeaderMobile breadcrumbs={breadcrumbs} />
          :
          <HeaderDesktop breadcrumbs={breadcrumbs} />
      }
    </header>
  )
}

export default Header
