import React from "react"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

if (isBrowser) {
  window.__lc = window.__lc || {};
  window.__lc.license = 8686691;
}

const initializeLiveChat = (n, t, c) => {
  function i(n) {
    return e._h ? e._h.apply(null, n) : e._q.push(n)
  }
  var e = {
    _q: [],
    _h: null,
    _v: "2.0",
    on: function () {
      i(["on", c.call(arguments)])
    },
    once: function () {
      i(["once", c.call(arguments)])
    },
    off: function () {
      i(["off", c.call(arguments)])
    },
    get: function () {
      if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
      return i(["get", c.call(arguments)])
    },
    call: function () {
      i(["call", c.call(arguments)])
    },
    init: function () {
      var n = t.createElement("script");
      n.async = !0
      n.type = "text/javascript"
      n.src = "https://cdn.livechatinc.com/tracking.js"
      t.head.appendChild(n)
    }
  };
  !n.__lc.asyncInit && e.init()
  n.LiveChatWidget = n.LiveChatWidget || e
}

function onFormSubmitted(data) {
  switch (data.type) {
    case 'prechat':
      isBrowser && window.gtag("event", "conversion", { send_to: "AW-627621910/TtSjCPPd1-0BEJaAo6sC" })
      break

    case 'ticket':
      isBrowser && window.gtag("event", "conversion", { send_to: "AW-627621910/TtSjCPPd1-0BEJaAo6sC" })
      break

    default:
      break
  }
}

const LiveChat = () => {
  if (isBrowser) {
    if (window.LiveChatWidget &&
      window.LiveChatWidget._h &&
      window.LiveChatWidget.get('customer_data')['status'] !== "chatting" &&
      window.LiveChatWidget.get('customer_data')['status'] !== "queued") {
      window.LiveChatWidget.call('destroy')
    }
    initializeLiveChat(window, document, [].slice)
    window.LiveChatWidget.on('form_submitted', onFormSubmitted)
    return <></>
  } else return null
}

export default LiveChat