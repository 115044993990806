import React from 'react'
import './styles.scss'

export const NeedHelp = () => {
  return (
    <div className="need-help">
      <div className="row no-gutters mt-6">
        <div className="offset-xl-2 col-xl-5 col-lg-6 mb-3">
          <h4 data-testid="needHelp" className="need-help my-0">
            Need help?</h4>
        </div>
        <div className="col-xl-5 col-lg-6 mb-3">
          <h5 className="my-0">
            <img className="mb-1 mr-2" src="https://res.cloudinary.com/dthskrjhy/image/upload/c_scale,w_25,h_25/v1590885011/Typenex/Personal%20Protective%20Equipment/Chat_icon.png" alt="Chat Icon" />
            <span data-testid="chatNow" role="button" className="chat-now">Chat now</span></h5>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="offset-xl-2 col-xl-5 col-lg-6 mb-3 mb-lg-4">
          <h5 className="text-nowrap my-0">
            <img className="mb-1 mr-2" src="https://res.cloudinary.com/dthskrjhy/image/upload/c_scale,w_25,h_25/v1590885024/Typenex/Personal%20Protective%20Equipment/Phone_icon.png" alt="Phone Icon" />
            <a data-testid="phoneNumber" href="tel:18668973639">866-897-3639</a></h5>
        </div>
        <div className="col-xl-5 col-lg-6 mb-3 mb-lg-4">
          <h5 className="text-nowrap my-0">
            <img className="mb-1 mr-2" src="https://res.cloudinary.com/dthskrjhy/image/upload/c_scale,w_25,h_25/v1590885027/Typenex/Personal%20Protective%20Equipment/Web_icon.png" alt="Email Icon" />
            <a data-testid="emailAddress" href="mailto:ppe@typenex.com">ppe@typenex.com</a></h5>
        </div>
      </div>
    </div>
  )
}

export default NeedHelp