import React from "react"
import { Link } from "gatsby"
import { useWindowSize } from "../../hooks/useWindowResize"
import { TABLET_MIN_WIDTH } from "../../constants"
import "./styles.scss"

const Footer = () => {
  const { width } = useWindowSize()

  return (
    <footer className="text-center m-auto">
      <div className="links-list">
        <div className="row justify-content-center text-left mx-auto w-100">
          {
            width >= TABLET_MIN_WIDTH &&
            <div className="col-sm-3">
              <Link to="/">
                <img data-testid="logo"
                  src="https://res.cloudinary.com/dthskrjhy/image/upload/c_scale,h_30/v1642518948/Typenex/new-website-images/typenexLogo.png"
                  alt="Typenex Medical Logo"
                  height={30}
                />
              </Link>
            </div>
          }
          <div className="col-sm-3 col-5">
            <div className="mb-2"><b>Company</b></div>
            <div className="mb-2"><Link to="/about/" activeClassName="active">About</Link></div>
            <div className="mb-2"><Link to="/contact/" activeClassName="active">Contact Us</Link></div>
            <div className="mb-2"><Link to="/international/" activeClassName="active">International</Link></div>
            <div className="mb-2"><Link to="/patents/" activeClassName="active">Patents</Link></div>
            <div className="mb-2"><Link to="/press/" activeClassName="active">Press</Link></div>
          </div>
          <div className="col-sm-3 col-5">
            <div className="mb-2"><b>Support</b></div>
            <div className="mb-2"><Link to="/privacy-policy/" activeClassName="active">Privacy Policy</Link></div>
            <div className="mb-2"><Link to="/resources/" activeClassName="active">Resources</Link></div>
            <div className="mb-2"><Link to="/supply-chain/" activeClassName="active">Supply Chain</Link></div>
            <div className="mb-2"><Link to="/trademarks/" activeClassName="active">Trademarks</Link></div>
          </div>
          <div className="col-md-3 col-sm-12">
            {width >= TABLET_MIN_WIDTH && <div className="mb-2"><b>Connect</b></div>}
            <div className={`d-flex w-100 flex-wrap ${width >= TABLET_MIN_WIDTH ? '' : 'justify-content-center mt-5'}`}>
              {
                [
                  {
                    img: "https://res.cloudinary.com/dthskrjhy/image/upload/v1592845855/Typenex/Facebook.png",
                    link: "https://www.facebook.com/typenexmedical/?ref=br_rs",
                    name: "facebook"
                  },
                  {
                    img: "https://res.cloudinary.com/dthskrjhy/image/upload/v1592845856/Typenex/Instagram.png",
                    link: "https://www.instagram.com/typenexmedical/",
                    name: "instagram"
                  },
                  {
                    img: "https://res.cloudinary.com/dthskrjhy/image/upload/v1592845857/Typenex/LinkedIn.png",
                    link: "https://www.linkedin.com/company/1013290",
                    name: "linkedin"
                  },
                  {
                    img: "https://res.cloudinary.com/dthskrjhy/image/upload/v1592845859/Typenex/Twitter.png",
                    link: "https://twitter.com/typenexmedical",
                    name: "twitter"
                  },
                  {
                    img: "https://res.cloudinary.com/dthskrjhy/image/upload/v1592845860/Typenex/Vimeo.png",
                    link: "https://vimeo.com/typenex",
                    name: "vimeo"
                  },
                ].map((icon, ind) => {
                  return (
                    <div key={ind} className="m-2">
                      <a data-testid={`${icon.name}-link`}
                        href={icon.link}
                        title={icon.name}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img data-testid={`${icon.name}-icon`}
                          src={icon.img}
                          className="img-center"
                          alt={`${icon.name} icon`}
                        />
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <span data-testid="copyright">
          Copyright &copy; {new Date().getFullYear()} Typenex&reg; Medical, LLC All
          Rights Reserved | 303 East Wacker Drive Chicago, Illinois 60601
        </span>
      </div>
      <div>
        <i>
          <span data-testid="disclaimer">
            The information contained on the Typenex&reg; Medical website is for
            general information purposes only. Some products may not be
            available for sale in all countries. Please contact your Typenex&reg;
            Medical representative for additional details.
          </span>
        </i>
      </div>
    </footer>
  )
}

export default Footer
