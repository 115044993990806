import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import './styles.scss'

export const ParentProductColumn = ({ parents, active, setActiveParent }) => {
  return (
    <div className="parent-column text-left py-3">
      {
        parents.map((parent, ind) => {
          let isActive = active?.name === parent.name
          return (
            <button key={ind} onClick={() => setActiveParent(parent)} className={`parent-btn w-100 py-2 ${isActive ? 'active' : ''}`}>
              <div className="d-flex justify-content-between align-items-center text-left">
                <div>{parent.name}</div>
                {
                  isActive &&
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                }
              </div>
            </button>

          )
        })
      }
    </div>
  )
}

export default ParentProductColumn