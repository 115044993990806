import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import ProductsDropdownMobile from '../ProductsDropdownMobile'
import './styles.scss'
import { useHierarchy } from '../../context/hierarchy-context'

const rowHeight = 46 // Roughly how tall is a row in the navbar in pixels

const getMenuHeight = (productNode) => {
  //Find the largest group of children and then multiply that by rowHeight
  let largestChildrenGroup = productNode.children.length
  productNode.children.forEach(child => {
    if (child.children?.length > largestChildrenGroup) {
      largestChildrenGroup = child.children.length
    }
  })

  return largestChildrenGroup * rowHeight
}

export const MobileNavbar = ({ opened }) => {
  const [productsDropdownClicked, setProductsDropdownClicked] = useState(0)
  const { hierarchy } = useHierarchy()
  const menuHeight = getMenuHeight(hierarchy)

  useEffect(() => {
    setTimeout(() => {
      setProductsDropdownClicked(0)
    }, 300)
  }, [opened])

  return (
    <div className={`mobile-navbar-menu text-left ${opened ? 'opened' : 'closed'}`} style={{ minHeight: menuHeight }}>
      <div className="m-4">
        <Link data-testid="home-link" activeClassName="active" to="/">Home</Link>
      </div>
      <div className="m-4">
        <ProductsDropdownMobile
          menuHeight={menuHeight}
          productNode={hierarchy}
          productsDropdownClicked={productsDropdownClicked}
          setProductsDropdownClicked={setProductsDropdownClicked} />
      </div>
      <div className="m-4">
        <Link data-testid="international-link" activeClassName="active" to="/international/">International</Link>
      </div>
      <div className="m-4">
        <Link data-testid="about-link" activeClassName="active" to="/about/">About</Link>
      </div>
      <div className="m-4">
        <Link data-testid="contact-us-link" activeClassName="active" to="/contact/">Contact&nbsp;Us</Link>
      </div>
      <div className="m-4">
        <Link data-testid="press-link" activeClassName="active" to="/press/">Press</Link>
      </div>

    </div>
  )
}

export default MobileNavbar