import React from 'react'
import { useWindowSize } from '../../hooks/useWindowResize'
import { TABLET_MIN_WIDTH } from '../../constants'
import './styles.scss'

export const PageHeader = ({ title, custom }) => {
  const { width } = useWindowSize()
  return (
    <div className="container mt-2 mb-4">
      <div className="row">
        <div className={`${custom ? 'col-lg-7' : 'col-lg-12'} d-flex align-items-end`}>
          <h1 className={`${width < TABLET_MIN_WIDTH ? 'mt-1' : 'mt-6'} mb-0 part-number-title`}>{title}</h1>
        </div>
        {custom &&
          <div className="col-lg-5 ml-lg-n3">
            {custom || null}
          </div>
        }
      </div>
    </div>
  )
}

export default PageHeader