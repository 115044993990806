import React from 'react'
import './styles.scss'

export const HamburgerIcon = ({ opened }) => {
  const strokeWidth = opened ? '5' : '2'
  return (
    <div className={`hamburger-icon ${opened ? 'opened' : 'closed'}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" viewBox="0 0 40 32" fill="none">
        <rect x="0.25" y="0.25" width="39.5" height="31.5" rx="2.75" stroke="#CECECE" strokeWidth="0" />
        <line className="top" x1="8.5" y1="7.5" x2="31.5" y2="7.5" stroke="#70686B" strokeWidth={strokeWidth} strokeLinecap="square" strokeLinejoin="square" />
        <line className="middle" x1="8.5" y1="15.5" x2="31.5" y2="15.5" stroke="#70686B" strokeWidth={strokeWidth} strokeLinecap="square" strokeLinejoin="square" />
        <line className="bottom" x1="8.5" y1="23.5" x2="31.5" y2="23.5" stroke="#70686B" strokeWidth={strokeWidth} strokeLinecap="square" strokeLinejoin="square" />
      </svg>
    </div>
  )
}

export default HamburgerIcon