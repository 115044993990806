import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import HamburgerIcon from '../../icons/HamburgerIcon'
import './styles.scss'
import MobileNavbar from './MobileNavbar'
import Algolia from '../Algolia'
import { useOverlay } from '../../context/overlay-context'
import { OutsideAlerter } from '../OutsideAlerter'

export const HeaderMobile = ({ breadcrumbs }) => {
  let activePage = breadcrumbs?.length > 0 && breadcrumbs[breadcrumbs.length - 1]
  const { setOverlay } = useOverlay()
  const [toggleOpened, setToggleOpened] = useState(false)
  const [searchOpened, setSearchOpened] = useState(false)
  const close = useCallback(() => {
    setToggleOpened(false)
    setSearchOpened(false)
    setOverlay(false)
  }, [setToggleOpened, setSearchOpened, setOverlay])

  useEffect(() => {
    close()
  }, [activePage, close])

  return (
    <OutsideAlerter onOutsideClick={() => close()}>
      <div className="header-mobile py-5 container">
        <div className="row">
          <div className="col p-0">
            <button
              onClick={() => {
                setSearchOpened(false)
                if (toggleOpened) {
                  setOverlay(false)
                  setTimeout(() => setToggleOpened(false), 100)
                }
                else {
                  setToggleOpened(true)
                  setTimeout(() => setOverlay(true), 200)
                }
              }}
              className="toggler p-0">
              <HamburgerIcon opened={toggleOpened} />
            </button>
          </div>
          <div className="col-6 p-0">
            <Link to="/">
              <img data-testid="logo"
                src={`https://res.cloudinary.com/dthskrjhy/image/upload/v1642518948/Typenex/new-website-images/typenexLogo.png`}
                alt="Typenex Medical Logo"
                width="100%"
              />
            </Link>
          </div>
          <div className="col p-0 text-right">
            <button
              onClick={() => {
                setToggleOpened(false)
                setOverlay(false)
                setSearchOpened(!searchOpened)
              }}
              className="toggler p-0">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        <MobileNavbar opened={toggleOpened} />
        <div className={`algolia-mobile-container px-5 ${searchOpened ? 'opened' : 'closed'}`}>
          <Algolia mobile />
        </div>
      </div>
    </OutsideAlerter>
  )
}

export default HeaderMobile